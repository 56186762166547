@import './variables.scss';

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(10%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.rideReady {
  background-color: $blue;
  min-height: 100vh;
  color: white;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  .coreScore {
    color: white;
  }
  .rideSets {
    margin-bottom: 25px;
  }
  .message {
    animation: slideUp .5s;
  }
  .prompt {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    pointer-events: none;
    opacity: 0;
    background-color: $dark-gray;
    transition: .3s;
    z-index: 5;
    button.secondary {
      background-color: rgba(0,0,0,0);
      border-color: white;
      color: white;
    }
    .icon {
      display: block;
      margin: 0 auto;
    }
    &.show {
      opacity: .95;
      pointer-events: all;
    }
    p {
      margin: 20px auto;
      font-size: 24px;
      line-height: 32px;
      max-width: 450px;
      text-align: center;
      @media (max-width: 767px) {
        font-size: 18px;
        line-height: 22px;
        max-width: 80vw;
        margin: 10px auto;
      }
    }
  }
  &.paused {
    background-color: $dark-gray;
  }
  &.started,
  &.paused {
    .rideSets {
      padding: 15px 0 115px;
    }
    footer {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      z-index: 6;
      &.fixed {
        position: fixed;
        background-color: $blue;
      }
    }
  }

  .currentRide {
    display: inline-flex;
    flex: 1;
    @media (max-width: 767px) {
      max-width: 100vw;
    }
    .rideStatus {
      padding-right: 5px;
      text-transform: uppercase;
      font-size: 14px;
      line-height: 36px;
      width: 175px;
      justify-content: flex-end;
      height: 36px;
      position: relative;
      transition: .5s;
      top: 0;
      display: flex;
      margin-top: 31px;
      @media (max-width: 767px) {
        position: fixed;
        background-color: $blue;
        z-index: 3;
        left: 0;
        right: 0;
        top: 65px !important;
        font-weight: bold;
        justify-content: center;
        width: 100vw;
        span {
          font-size: 14px !important;
          margin-right: 3px !important;
        }

      }
      span {
        display: inline-block;
        margin-right: 10px;
        font-size: 36px;
      }
    }
  }

  footer {
    font-size: 24px;
    text-align: center;
    line-height: 28px;
    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 20px;
    }
    span {
      color: $green;
      text-transform: uppercase;
      font-weight: bold;
    }
    .timeout {
      opacity: 0;
      pointer-events: none;
      margin-top: -120px;
      transition: .5s;
      &.active {
        margin-top: 35px;
        pointer-events: all;
        display: block;
        opacity: 1;
      }
    }
  }
}
