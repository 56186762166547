@import './variables.scss';

@keyframes slideIn {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

.coreScore {
  text-transform: uppercase;
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-bottom: -20px;
  justify-content: center;
  color: $blue;
  .star {
    width: 15px;
    height: 15px;
    margin-left: 10px;
  }
  span {
    font-size: 24px;
    margin-left: 5px;
    color: $green;
    font-weight: bold;
  }
}

.rideConfig {
  padding-bottom: 180px;
  h2 {
    margin: 0;
    padding: 10px 0px;
    text-align: center;
    font-weight: normal;
    font-size: 24px;
    color: $blue;
    border-bottom: 1px solid $light-gray;
  }
  ol {
    margin: 0px;
    padding: 0;
    li {
      overflow: hidden;
      list-style-type: none;
      .set {
        border-bottom: 1px solid $light-gray;
        display: flex;
        padding: 30px 15px;
        line-height: 40px;
        justify-content: space-around;
        font-size: 18px;
        text-transform: uppercase;
        @media (max-width: 767px) {
          align-items: center;
          font-size: 14px;
          padding: 10px;
        }
      }
      &.isNew .set {
        animation: slideIn .5s;
      }
      input {
        font-size: 36px;
        line-height: 36px;
        width: auto;
        text-align: center;
        display: inline-block;
        height: 52px;
        margin: 0;
        border: 0;
        @media (max-width: 1024px) {
          height: 50px;
          font-size: 24px;
        }
      }
      .parameter {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 40px;
        line-height: 20px;
        @media (max-width: 767px) {
          height: 70px;
          &.parameter-spins,
          &.parameter-degrees {
            flex-direction: column;
          }
          label {
            margin-top: -10px !important;
          }
        }
        input[type=checkbox] {
          display: none;
        }
        label {
          margin: 0 10px;
        }
        .stepper {
          cursor: pointer;
          margin: 0 5px;
          user-select: none;
          -webkit-user-select: none;
        }
        &.parameter-direction {
          cursor: pointer;
          .direction-label {
            line-height: 0;
            transition: line-height .3s;
            :first-child {
              opacity: 0;
              transition: opacity .3s;
            }
            &.direction-ccw-label {
              line-height: 1;
              :first-child {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
  footer {
    position: fixed;
    border-top: 1px solid $light-gray;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
  }
}

.delete {
  background-image: url('../img/x.svg');
  background-repeat: no-repeat;
  background-position: center center;
  height: 35px;
  width: 35px;
  border: 2px solid $light-gray;
  border-radius: 100%;
  pointer-events: none;
  opacity: 0;
  flex-shrink: 0;
  &.active {
    opacity: 1;
    pointer-events: all;
    cursor: pointer;
  }
}
.stepper {
  cursor: pointer;
  width: 35px;
  height: 35px;
  border: 2px solid $light-gray;
  border-radius: 100%;
  display: block;
  background-image: url('../img/caret.svg');
  background-size: 20px;
  background-position: left 50% center;
  background-repeat: no-repeat;
  /* Don't let a flex layout squeeze this element */
  flex-shrink: 0;
  @media (max-width: 767px) {
    display: none;
  }
  &.less {
    transform: rotate(180deg)
  }
}

