@import './variables.scss';

.page.login {
  position: relative;
  z-index: 4;
  padding-top: 0;
  height: 100vh;
  background-color: $dark-gray;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .brand {
    display: block;
    margin: -25px auto 25px;
    width: 300px;
  }
  footer {
    margin-top: 20px;
    text-align: center;
  }
}
