@import './variables.scss';

.navbarWrapper {
  z-index: 5;
  background: $blue;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;

  &.stopped {
    background: $red;
  }

  &.completed, &.paused {
    background: $dark-gray;
  }
  @media (min-width: 768px) {
    .modal {
      display: none;
    }
  }

  .icon.pair {
    background-image: url('../img/icon-pair-green.svg');
  }

  .navbar {
    position: relative;
    height: 40px;
    padding: 20px;
    .icon {
      background-position: center;
      background-size: 25px;
      width: 40px;
      height: 40px;
      cursor: pointer;
    }
    .back {
      float: left;
      background-image: url('../img/back.png');
    }
    .logout {
      float: right;
      background-image: url('../img/logout.png');
    }
    h2 {
      color: white;
      font-size: 24px;
      line-height: 40px;
      font-weight: bold;
      text-align: center;
      margin: 0 40px;
    }
  }

  .machineInfo {
    position: relative;
    margin-top: -20px;
    line-height: 40px;
    height: 40px;
    font-weight: normal;
    font-size: 14px;
    color: white;
    padding: 0px 20px 5px;
    user-select: none;

    @media (max-width: 767px) {
      .unpairPrompt {
        align-items: center;
        margin-top: -3px;
        line-height: 16px;
      }
    }

    .statusbar {
      transition: opacity 0.3s;
      &.pressed .machineName {
        animation: shake 2s cubic-bezier(.36,.07,.19,.97) both infinite;
      }
      &.hidden {
        opacity: 0;
      }

      .icon {
        background-position: center;
        margin-right: 0.5em;

        &.brand {
          /*
            We are setting the height to an arbitrary value here, because the width is what determines the icon's size
          */
          width: 15px;
          height: 30px;
        }
        &.angle {
          width: 15px;
          height: 30px;
        }
      }

      /* Labels are text with icons */
      .statusbar-label {
        display: flex;
        align-items: center;
      }

      /* Items are the left and right indicators */
      .statusbar-item {
        width: 75px;
        &.status {
          float: left;
          font-weight: bold;
          text-transform: uppercase;
          justify-content: flex-start;
        }
        &.tilt {
          float: right;
          font-weight: bold;
          justify-content: flex-end;
        }
      }

      .machineName {
        font-size: 14px;
        font-weight: normal;
        cursor: pointer;
        text-transform: uppercase;
        text-align: center;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        margin: 0 auto;
        @media (max-width: 445px) {
          text-overflow: ellipsis;
          max-width: 180px;
        }
        .icon {
          position: relative;
          top: 10px;
        }
      }
    }

    .unpairPrompt {
      @media (max-width: 767px) {
        display: none;
      }
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      padding: 0 20px;
      display: flex;
      transform: translateX(-100%);
      transition: transform .3s;
      &.visible {
        transform: translateX(0);
      }
      button {
        background: none;
        border: none;
        font-family: 'Cairo';
        margin-left: 20px;
        text-transform: uppercase;
        font-weight: bold;
        cursor: pointer;
        &.cancel {
          color: $light-gray;
        }
        &.confirm {
          color: $green;
        }
      }
      .message {
        flex: 1;
      }
    }

  }
}
