@import './variables.scss';

.rideComplete {
  background-color: $dark-gray;
  color: white;
  height: 100vh;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  h3 {
    margin: 20px 0px 0px;
  }
  .returnHome {
    margin-bottom: 0px;
    p {
      color: $green;
    }
  }
  .rideSets {
    padding-top: 0;
    li {
      &:before, &:after {
        display: none;
      }
    }
  }
  .button {
    margin-top: 50px;
  }
}

.coreScoreWrapper {
  display: flex;
  animation: pulse 1s;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-top: 10px;
  .trophy {
    height: 100px;
    width: 100px;
  }
  .star {
    width: 25px;
    height: 25px;
    margin-right: 5px;
  }
  .score {
    margin-top: -15px;
    font-size: 36px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  footer {
    font-size: 16px;
    text-align: center;
    margin: 0 auto;
    display: block;
    text-transform: uppercase;
    font-weight: bold;
  }
  &.best {
    margin-top: 5px;
    flex-direction: column;
    .score {
      width: 100px;
      font-size: 30px;
      color: white;
      position: absolute;
    }
  }
}
