@import './variables.scss';

.rideHalted {
  background-color: $red;
  padding: 0px;
  margin: 0;
  height: 100vh;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .instructions {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1;
  }
  footer, .instructions {
    opacity: 0;
    transition: .5s;
    &.show {
      opacity: 1;
    }
  }
  .prompt {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    pointer-events: none;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    button.secondary {
      border-color: white;
      color: white;
      background-color: rgba(0,0,0,0);
    }
    .icon {
      margin-bottom: 50px;
    }
    p {
      top: 20px;
    }
    &.show {
      transition: .5s;
      pointer-events: all;
      opacity: 1;
      p {
        top: -25px;
      }
    }
  }
  footer {
    padding: 50px;
    text-align: center;
  }
  p {
    position: relative;
    font-size: 24px;
    text-align: center;
    max-width: 420px;
    transition: .3s;
    margin: 0 auto 25px auto;
    line-height: 32px;
    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 22px;
      max-width: 80vw;
      margin: 10px auto -30px;
    }
  }
}
