@import './variables.scss';

.page.patientFind {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
  @media (max-width: 767px) {
    margin: 10px 0px;
    .button {
      margin-top: 0px !important;
    }
  }
  .warning {
    display: flex;
    align-items: center;
    transition-property: opacity, transform;
    transition-duration: 0.35s;
    opacity: 0;
    margin-top: 30px;
    transform: translateY(50%);
    @media (max-width: 767px) {
      margin-top: 0px;
    }
    &.show {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.idEntry {
  text-align: center;
  width: 300px;
  border: none;
  border-bottom: 1px solid $light-gray;
  font-size: 36px;
  height: 70px;
  @media (max-width: 767px) {
    font-size: 24px;
    height: 36px;
  }
}

.pinPad {
  margin: 50px 0px 0px;
  @media (max-width: 767px) {
    margin: 10px 0px;
  }
  button {
    width: 75px;
    height: 75px;
    font-family: 'Cairo';
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    cursor: pointer;
    user-select: none;
    transition: .15s;
    font-size: 36px;
    font-weight: bold;
    @media (max-width: 767px) {
      font-size: 24px;
      width: 50px;
      height: 50px;
      margin: 10px;
    }
  }
  .backspace {
    background-color: white;
    border: 2px solid rgba(0,0,0,0);
    @media (max-width: 767px) {
      &.icon {
        width: 35px;
        height: 35px;
      }
    }
    &:active {
      i {
        transform: scale(.9);
      }
    }
  }
  .number {
    border: 2px solid $blue;
    border-radius: 100%;
    background-color: white;
    &:active {
      background-color: $blue;
      color: white;
    }
  }
}

